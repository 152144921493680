<template>
    <div class="container">
        <div class="menu">
            <Menu theme="light" :active-name="showMenu" @on-select="selectMenu">
                <MenuItem name="baseInfo">
                    <Icon type="ios-information-circle" />
                    基本信息
                </MenuItem>
                <MenuItem name="writeInfo">
                    <Icon type="ios-create" />
                    填写表单设置
                </MenuItem>
                <MenuItem name="moreSetting">
                    <Icon type="md-chatbubbles" />
                    更多设置
                </MenuItem>
            </Menu>
        </div>
        <div class="content">
            <Form :model="formData" :label-colon="true" :label-width="120" v-show="showMenu == 'baseInfo'" :disabled="true">
                <FormItem>
                    <span slot="label" class="validate">标题:</span>
                    <Input v-model.trim="formData.title"></Input>
                </FormItem>
                <FormItem>
                    <span slot="label" class="validate">公布时间:</span>
                    <DatePicker :options="dateOption" :transfer="true" v-model="formData.time" type="datetimerange" style="width: 400px" placeholder="请选择公布开始时间和结束时间"></DatePicker>
                    <Tooltip placement="top" :delay="500">
                        <Icon type="md-help-circle" style="margin: 10px"></Icon>
                        <div slot="content">
                            <p>到了公共开始时间，终端上才可查看到；</p>
                            <p>如果后台发布了，没有到开始时间，终端上也查看不到</p>
                        </div>
                    </Tooltip>
                </FormItem>
                <FormItem label="联系电话">
                    <Input v-model.trim="formData.contactMobile" style="width: 200px"></Input>
                    <span style="color: #aaa; width: 200px; margin-left: 16px">说明：移动端可以直接拨打</span>
                </FormItem>
                <FormItem>
                    <span slot="label" class="validate">发布终端:</span>
                    <CheckboxGroup v-model="formData.terminal">
                        <Checkbox label="0">全部</Checkbox>
                        <Checkbox label="1">TV</Checkbox>
                        <!-- <Checkbox label="2">PC</Checkbox> -->
                        <Checkbox label="3">APP</Checkbox>
                        <Checkbox label="4">小程序</Checkbox>
                        <!-- <Checkbox label="5">公众号</Checkbox>
            <Tooltip placement="top" :delay="500">
              <Icon type="md-help-circle" style="margin: 10px"></Icon>
              <div slot="content">
                <p>
                  勾选公众号，则会对发布范围中关注过社区随约服务网上驿站公众号的用户主动推送此活动。
                </p>
              </div>
            </Tooltip> -->
                    </CheckboxGroup>
                </FormItem>
                <FormItem label="发布范围">
                    <span slot="label" class="validate">发布范围</span>
                    <Select v-model="prov" transfer style="width: 20%" :max-tag-count="3" placeholder="请选择省" multiple filterable @on-change="changeProv">
                        <Option :value="item.value" v-for="(item, index) in proList" :key="index">{{ item.label }}</Option>
                    </Select>
                    <Select v-model="city" transfer style="width: 20%" :max-tag-count="3" placeholder="请选择市" multiple filterable :disabled="cityDisabled" @on-change="changeCity">
                        <Option :value="item.value" v-for="(item, index) in cityList" :key="index">{{ item.label }}</Option>
                    </Select>
                    <Select v-model="region" transfer style="width: 20%" :max-tag-count="3" placeholder="请选择区" :disabled="regionDisabled" multiple filterable @on-change="changeRegion">
                        <Option :value="item.value" v-for="(item, index) in regionList" :key="index">{{ item.label }}</Option>
                    </Select>
                    <Select v-model="stree" transfer style="width: 20%" :max-tag-count="3" placeholder="请选择街道/乡镇" :disabled="streeDisabled" multiple filterable @on-change="changeStree">
                        <Option :value="item.value" v-for="(item, index) in streeList" :key="index">{{ item.label }}</Option>
                    </Select>
                    <Select v-model="project" transfer style="width: 20%" :max-tag-count="3" placeholder="请选择社区" :disabled="projectDisabled" multiple filterable>
                        <Option :value="item.value" v-for="(item, index) in projectList" :key="index">{{ item.label }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="封面图片">
                    <span slot="label" class="validate">封面图片:</span>
                    <img ref="image" :src="formData.photo" alt="" width="200" style="display: block; margin-bottom: 10px" />
                </FormItem>
                <!-- <iframe
          name="ueditor"
          id="ueditor"
          src="/ueditor/index.html?titlename=活动详情（必填）"
          style="width: 100%; height: 500px; border: 0px"
          @load="loadUeditor"
        ></iframe> -->
                <iframe name="ueditor" id="ueditor" src="/mobileeditor/#/index?titlename=活动详情（必填）" style="width: 100%; height: 500px; border: 0px" @load="loadUeditor"></iframe>
                <Card style="position: relative; top: -20px" dis-hover v-if="formData.operationVo">
                    <p slot="title">拟制信息</p>
                    <FormItem style="display: inline-block; width: 308px; margin-bottom: 0px" label="拟制员工">
                        <Input :value="formData.operationVo.createName || ''" disabled style="width: 200px"></Input>
                    </FormItem>
                    <FormItem style="display: inline-block; width: 450px; margin-bottom: 0px" label="拟制时间">
                        <DatePicker type="datetime" :value="formData.operationVo.createTime | toggleTime(that)" disabled style="width: 300px"></DatePicker>
                    </FormItem>
                </Card>
                <Card style="position: relative; top: -20px; border-top: none" dis-hover v-if="formData.status == '2' || formData.status == '3' || formData.status == '4' || formData.status == '5'">
                    <p slot="title">审核信息</p>
                    <FormItem style="display: inline-block; width: 308px; margin-bottom: 0px" label="审核员工">
                        <Input v-if="formData.operationVo" :value="formData.operationVo.reviewName" disabled style="width: 200px"></Input>
                    </FormItem>
                    <FormItem v-if="formData.operationVo" style="display: inline-block; width: 450px; margin-bottom: 0px" label="审核时间">
                        <DatePicker type="datetime" :value="formData.operationVo.reviewTime | toggleTime(that)" disabled style="width: 300px"></DatePicker>
                    </FormItem>
                </Card>
                <Card style="position: relative; top: -20px; border-top: none" dis-hover v-if="formData.status == '4' || formData.status == '5'">
                    <p slot="title">发布信息</p>
                    <FormItem v-if="formData.operationVo" style="display: inline-block; width: 308px; margin-bottom: 0px" label="发布员工">
                        <Input v-if="formData.operationVo" :value="formData.operationVo.publishName" disabled style="width: 200px"></Input>
                    </FormItem>
                    <FormItem v-if="formData.operationVo" style="display: inline-block; width: 450px; margin-bottom: 0px" label="发布时间">
                        <DatePicker type="datetime" :value="formData.operationVo.publishTime | toggleTime(that)" disabled style="width: 300px"></DatePicker>
                    </FormItem>
                </Card>
                <Card style="position: relative; top: -20px; border-top: none" dis-hover v-if="formData.dismissList && formData.dismissList.length > 0">
                    <p slot="title">驳回信息</p>
                    <div v-for="(item, index) in formData.dismissList" :key="index">
                        <FormItem style="display: inline-block; width: 308px; margin-bottom: 0px" label="驳回员工">
                            <Input :value="item.staffName" disabled style="width: 200px"></Input>
                        </FormItem>
                        <FormItem style="display: inline-block; width: 450px; margin-bottom: 0px" label="发布时间">
                            <DatePicker type="datetime" :value="item.gmtCreate | toggleTime(that)" disabled style="width: 300px"></DatePicker>
                        </FormItem>
                        <div style="color: red; margin: 20px 0 20px 48px">
                            驳回原因：
                            <span>{{ item.reason }}</span>
                        </div>
                        <Divider v-if="index != formData.dismissList.length - 1" />
                    </div>
                </Card>
            </Form>
            <keep-alive>
                <Form :model="formData" v-if="showMenu == 'writeInfo'" :disabled="true">
                    <!-- 表单模板 -->
                    <div style="margin: 0 10px">
                        <PreviewForm :previewFormData="formDetailVo"></PreviewForm>
                    </div>
                </Form>
            </keep-alive>
            <Form :model="formData" v-show="showMenu == 'moreSetting'" :label-colon="true" :label-width="150" :disabled="true">
                <FormItem>
                    <span slot="label" class="validate">信息来源:</span>
                    <Input v-model="formData.source"></Input>
                </FormItem>
                <FormItem>
                    <span slot="label" class="validate">是否可代填报:</span>
                    <RadioGroup v-model="ruleVos[3].ruleValue">
                        <Radio label="1">是</Radio>
                        <Radio label="0">否</Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem v-if="ruleVos[3].ruleValue == '1'">
                    <span slot="label" class="validate">代报填写信息:</span>
                    <Select v-model="ruleVos[4].ruleValue" style="width: 200px">
                        <Option value="1">姓名及手机号</Option>
                        <Option value="2">姓名、手机号及身份证号</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <span slot="label" class="validate">填报用户签名确认:</span>
                    <RadioGroup :value="ruleVos[5].ruleValue">
                        <Radio label="1">需签名</Radio>
                        <Radio label="0">不用签名</Radio>
                    </RadioGroup>
                    <span style="color: rgb(170, 170, 170); margin-left: 16px">注：用户填写后需要在线签名确认</span>
                </FormItem>
                <FormItem>
                    <span slot="label" class="validate">限制每人限填一次:</span>
                    <RadioGroup :value="ruleVos[6].ruleValue">
                        <Radio label="1">限制</Radio>
                        <Radio label="0">不限制</Radio>
                    </RadioGroup>
                    <span style="color: rgb(170, 170, 170); margin-left: 16px">注：每人只可填写1次，不会有重复数据，适用于严格限制参与人数的情况</span>
                </FormItem>

                <FormItem>
                    <span slot="label" class="validate">限制填写人修改结果:</span>
                    <RadioGroup :value="ruleVos[7].ruleValue" @on-change="changeruleVos6">
                        <Radio label="1">限制</Radio>
                        <Radio label="0">不限制</Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem>
                    <span slot="label" class="validate">是否推荐:</span>
                    <RadioGroup v-model="formData.recommend">
                        <Radio label="1">推荐</Radio>
                        <Radio label="0">不推荐</Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem>
                    <span slot="label">限制填写次数：</span>
                    <InputNumber placeholder="限制表单总填写数" v-model="ruleVos[8].ruleValue" style="width: 200px" :max="9999999999" :min="1"></InputNumber>
                </FormItem>
            </Form>
            <Form :model="formData" v-show="showMenu == 'moreSetting'" :label-colon="true" :label-width="150" :disabled="infoStatus == '待发布' ? false : true">
                <!-- <FormItem>
          <span slot="label" >推送方式:</span>
          <Checkbox v-model="formData.pushChannel" >小程序订阅通知</Checkbox>
        </FormItem> -->

                <FormItem label="小程序订阅通知">
                    <CheckboxGroup v-model="pushType" @on-change="changePushType">
                        <Checkbox :disabled="pushlishRoot == '1003' ? false : true" label="1">我的用户</Checkbox>
                        <span style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的服务过的居民可收到推送信息。</span>
                        <br />
                        <Checkbox :disabled="pushlishRoot == '1003' ? false : true" label="2">我的管理员</Checkbox>
                        <span style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的员工档案在职管理员可收到推送信息。</span>
                        <br />
                        <!-- <Checkbox label="3">我的畅联组</Checkbox>
                        <span v-if="pushType && pushType[0] == 3">
                            <Input style="width: 300px; margin-left: 16px" v-model="formData.groupNames" />
                            <Button type="primary" @click="groupStatus = true">选群</Button>
                        </span>
                        <br />
                        <Checkbox label="4" v-if="dimensionId == 2 || this.buttonRoot == '1003'">社区线上用户（原推送功能）</Checkbox>
                        <span v-if="dimensionId == 2 || this.buttonRoot == '1003'" style="color: #aaa; width: 200px; margin-left: 16px">注：勾选后，订阅了社区通知的所有线上居民可收到推送信息。</span> -->
                         <Checkbox :disabled="pushlishRoot == '1003' ? false : true" v-if="dimensionId == 2 || pushlishRoot == '1003'" label="5">社区注册用户</Checkbox>
                        <span v-if="dimensionId == 2 || pushlishRoot == '1003'" style="color: #aaa; width: 200px; margin-left: 16px">
                            注：勾选后，订阅了社区通知且 激活了社区（村）地址的居民用户可收到推送信息。
                        </span>
                        <br />
                        <Checkbox :disabled="pushlishRoot == '1003' ? false : true" v-if="dimensionId == 2 || pushlishRoot == '1003'" label="4">社区访问用户</Checkbox>
                        <span v-if="dimensionId == 2 || pushlishRoot == '1003'" style="color: #aaa; width: 200px; margin-left: 16px">
                            注：勾选后，订阅了社区通知的所有访问过本社区的用户可收到推送信息
                        </span>
                        <br />
                       
                    </CheckboxGroup>
                </FormItem>
            </Form>
            <Form :model="formData" v-show="showMenu == 'moreSetting'" :label-colon="true" :label-width="150" :disabled="true">
                <FormItem label="参与获得积分">
                    <InputNumber style="width: 200px" :max="999999999" :min="0" v-model="ruleVos[1].ruleValue"></InputNumber>
                </FormItem>
                <FormItem label="按钮名称">
                    <Select v-model="ruleVos[2].ruleValue" style="width: 200px" transfer>
                        <Option value="立即报名">立即报名</Option>
                        <Option value="立即填报">立即填报</Option>
                        <Option value="立即参与">立即参与</Option>
                        <Option value="立即预约">立即预约</Option>
                        <!-- <Option value="其他">其他</Option> -->
                        <Option value="自定义">自定义</Option>
                    </Select>
                    <span style="color: #aaa; margin-left: 16px">注：用户参与的时候看到的按钮名称</span>
                </FormItem>
                <FormItem v-if="ruleVos[2].ruleValue == '其他' || ruleVos[2].ruleValue == '自定义'">
                    <span slot="label" class="validate">名称:</span>
                    <Input v-model="formData.buttonName" :maxlength="10" placeholder="限10字" style="width: 200px"></Input>
                </FormItem>
                <FormItem>
                    <!-- <span slot="label" class="validate">身份校验:</span>
            <RadioGroup v-model="ruleVos[0].ruleValue">
                  <Radio label="1">
                      实名认证 <span style="color: #aaa; margin-left: 16px"
                        >注：勾选后可获填报用户的姓名、性别、年龄、手机号、身份证号，已认证的用户无需重复认证。</span
                      >
                  </Radio>
                  <Radio label="2">
                      网上家园认证
                      <span style="color: #aaa; margin-left: 16px"
                        >注：勾选后可获填报用户的姓名、手机号，已认证的用户无需重复认证</span
                      >
                  </Radio>
                  <Radio label =''> 
                      不认证 
                      <span style="color: #aaa; margin-left: 16px"
                        >注：不认证则只可获得报名用户的手机号</span
                      >
                  </Radio>
            </RadioGroup> -->
                    <Checkbox v-model="ruleVos[0].ruleValue">实名认证</Checkbox>
                    <span style="color: #aaa; margin-left: 16px">注：勾选后可获填报用户的姓名、性别、年龄、手机号、身份证号，不勾选则只可获得报名用户的手机号</span>
                </FormItem>
            </Form>
        </div>
        <LiefengModal title="驳回原因" :value="rejectStatus" @input="rejectStatusFn" width="500px">
            <template v-slot:contentarea>
                <Mymodel :fullscreen="false" :value="toggle1" :toggle="true" :textObj="textObj" title="文本预览" :toggle-fn2="toggleFn2"></Mymodel>
                <Input v-model.trim="reason"></Input>
            </template>
            <template v-slot:toolsbar>
                <Button
                    type="info"
                    @click="
                        cancelModalTip = true
                        rejectStatusFn(false)
                    "
                    style="margin-right: 10px"
                >
                    取消
                </Button>
                <Button type="primary" @click="publish('')">确定</Button>
            </template>
        </LiefengModal>
        <!-- 推送选群 -->
        <LiefengModal :value="groupStatus" title="选择畅联组" :fullscreen="true" @input="changeGroupStatus">
            <template v-slot:contentarea>
                <CheckGroup :buttonRoot="buttonRoot" ref="checkGroup" @getGroup="getGroup"></CheckGroup>
            </template>
            <template v-slot:toolsbar>
                <!-- <Button type="info" @click="cancelGroup" style="margin-right:10px"> 取消 </Button> -->
                <Button type="primary" @click="saveGroup">保存</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import LiefengEditor from "@/components/LiefengEditor"
import LiefengModal from "@/components/LiefengModal"
import PreviewForm from "@/views/form/childrens/previewForm"
import Mymodel from "@/views/activity/Mymodel"
import CheckGroup from "./checkgroup.vue"

export default {
    props: ["menuCodeObj", "activeId", "infoStatus", "publicRecommend", "orgCode", "buttonRoot"],
    data() {
        return {
            groupStatus: false,
            dimensionId: "",
            pushType: [],
            that: this,
            //富文本预览
            toggle1: false, //
            textObj: {
                spinShow: false,
                signInUrl: "", //预览二维码url
            },
            showMenu: "baseInfo",
            formData: { terminal: [], time2: [], time: [], pushChannel: false }, //新增修改时提交的数据第一层
            cycleDates: [], //重复内容--日期 / 周
            cycleDateList: (() => {
                let arr = []
                for (let i = 1; i <= 32; i++) {
                    arr.push({
                        value: i,
                        label: i,
                    })
                }
                return arr
            })(),
            cycleTimeList: [
                { value: 1, label: "周一" },
                { value: 2, label: "周二" },
                { value: 3, label: "周三" },
                { value: 4, label: "周四" },
                { value: 5, label: "周五" },
                { value: 6, label: "周六" },
                { value: 7, label: "周日" },
            ],
            cycleTimes: [
                {
                    limits: [
                        {
                            attrType: "person",
                            attrValue: "",
                        },
                    ],
                    timeValue: [],
                },
            ], //开展时间
            dateOption: {
                shortcuts: [
                    //公布时间配置项
                    {
                        text: "1天",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 1)
                            return [start, end]
                        },
                    },
                    {
                        text: "7天",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 7)
                            return [start, end]
                        },
                    },
                    {
                        text: "30天",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 30)
                            return [start, end]
                        },
                    },
                    {
                        text: "365天",
                        value() {
                            const end = new Date()
                            const start = new Date()
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 365)
                            return [start, end]
                        },
                    },
                ],
            },
            // 发布终端临时变量
            terminalList: [],
            dataScopeIdList: [], //发布范围列表
            city: [],
            region: [],
            stree: [],
            project: [],
            cityList: [],
            regionList: [],
            streeList: [],
            projectList: [],

            regionDisabled: true,
            streeDisabled: true,
            projectDisabled: true,
            // 上传图片
            action: "",
            file: "",
            imageName: "",
            imagePath: "",
            uploadData: {},
            ruleVos: [
                //规则
                {
                    ruleCode: "authentication", //实名认证
                    ruleValue: "",
                },
                {
                    ruleCode: 'participate_integral"', //参与获得积分
                    ruleValue: 0,
                },
                {
                    ruleCode: "button_name", //按钮名称
                    ruleValue: "",
                },
                {
                    ruleCode: "whether_registration", //代报名
                    ruleValue: "",
                },
                {
                    ruleCode: "registration_type", //代报填写信息
                    ruleValue: "",
                },
                {
                    ruleCode: "signature", //填报用户签名确认
                    ruleValue: "",
                },
                {
                    ruleCode: "submit_only", //限制每人限填一次
                    ruleValue: "",
                },
                {
                    ruleCode: "modify_results",
                    ruleValue: "0",
                },
                {
                    ruleCode: "join_number_limit",
                    ruleValue: null,
                },
            ],
            // 模板弹窗
            formTemplateStatus: false,
            formTemplateDate: [],
            page: 1,
            pageSize: 9,
            total: 0,
            tabName: 0,
            changeFormShow: false, //控制显示新增还是修改表单组件
            infoId: "",
            formDetailVo: {}, //切换菜单时暂存模板表单数据
            // 修改必传id
            appointmentId: "",

            // 驳回
            reason: "",
            rejectStatus: false,
            cancelModalTip: true,

            pushlishRoot:"",

            prov:[],
            proList: [],
            cityDisabled: true,
        }
    },
    methods: {
        changeGroupStatus(val) {
            this.groupStatus = val
        },
        cancelGroup() {
            this.groupStatus = false
        },
        saveGroup() {
            let list = this.$refs.checkGroup.rightList
            console.log(list)
            if (list.length == 0) {
                this.$Message.warning({
                    content: "请选择群",
                    background: true,
                })
                return
            }
            let groupName = []
            let groupId = []
            list.map(item => {
                groupName.push(item.groupName)
                groupId.push(item.groupId)
            })
            this.formData.groupNames = groupName.join(",")
            this.formData.channels = groupId.join(",")
            this.groupStatus = false
        },
        changePushType(data) {
            if (data && data.length) {
                this.pushType = []
                this.pushType.push(data[data.length - 1])
                // this.formData.pushType = [...data[1]]
            }
        },
        toggleFn2() {
            this.toggle1 = false
        },
        // 驳回
        rejectStatusFn(status) {
            if (!status && this.cancelModalTip) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "您正在关闭此窗口，信息将不会保存，是否继续",
                    onOk: () => {
                        this.reason = ""
                        this.rejectStatus = status
                    },
                })
            } else {
                this.rejectStatus = status
            }
        },
        reject() {
            this.rejectStatus = true
        },
        passAndSend() {
            this.$Modal.confirm({
                title: "加急说明",
                content:
                    "<p>1.经审核提交发布的合格内容，在点击【加急】按钮后，该信息将通过【加急】通道，在15分钟内完成发布流程。</p><p> 2.根据市民政局主管部门的批准意见，【加急】发布的信息，仅限于疫情防控或类似需要紧急发布的信息，具体标准由审核人员参照批准意见加强管控。</p>",
                onOk: () => {
                    this.addPush("1")
                },
            })
        },
        // 发布
        publish(releaseWay) {
            // if(this.formData.pushChannel == true){
            //   if(!this.rejectStatus){
            //     this.$Modal.confirm({
            //       title: "退出提示",
            //       content: "<p>当前发布包括推送，是否继续发布？</p>",
            //       onOk: () => {
            //         this.addPush(releaseWay || '')
            //       },
            //       onCancel:()=>{
            //         this.$emit('failUp')
            //       }
            //     });
            //   }else{
            //    this.addPush(releaseWay || '')
            //   }
            // }else{
            //     this.addPush(releaseWay || '')
            // }
            this.addPush(releaseWay || "")
        },
        addPush(releaseWay) {
            if (this.rejectStatus && !this.reason) {
                this.$Message.error({
                    background: true,
                    content: "请输入驳回原因",
                })
                return
            }
            this.$post("/info/api/pc/information/v2/change", {
                status: this.rejectStatus ? "2" : this.infoStatus == "待审核" ? "3" : this.infoStatus == "待发布" ? "4" : this.infoStatus == "已发布" ? "5" : this.infoStatus == "已过期" ? "6" : "",
                recommend: this.publicRecommend && this.publicRecommend != "" ? this.publicRecommend : this.formData.recommend,
                sort: "100",
                id: this.activeId,
                staffName: parent.vue.loginInfo.userinfo.realName,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: this.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                terminal: "2",
                reason: this.reason,
                pushChannel: this.formData.pushChannel ? "4" : "",
                releaseWay: releaseWay ? releaseWay : "",
                pushType: this.formData.pushType && this.formData.pushType[0] ? this.formData.pushType[0] : "",
                channels: this.formData.pushType && this.formData.pushType[0] == 3 ? this.formData.channels : "",
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        background: true,
                        content: "操作成功",
                    })
                    this.cancelModalTip = false
                    this.$emit("saveSuccess")
                } else {
                    this.$Message.error({
                        background: true,
                        content: res.desc,
                    })
                }
            })
        },
        loadUeditor() {
            if (this.formData.content != "" && this.formData.content != null && this.formData.content != undefined) {
                var v_Obj = document.getElementById("ueditor").contentWindow //获取iframe对象
                v_Obj.setContent(this.formData.content) //写入编辑器富文本内容
                v_Obj.setPreview(true)
            } else {
                setTimeout(this.loadUeditor, 300)
            }
        },
        contentFn() {
            //富文本预览
            var v_Obj = document.getElementById("ueditor").contentWindow //获取iframe对象
            this.formData.content = v_Obj.getContent() //获取富文本内容

            if (!this.formData.content) {
                this.$Message.warning({
                    background: true,
                    content: "暂没内容，点击编辑吧！",
                    duration: 2,
                })
                return
            }
            this.$Message.loading({
                background: true,
                content: "正在加载，请稍等...",
            })

            let params = {
                content: this.formData.content,
            }
            this.$post(
                "/datamsg/api/pc/wxqr/createWxAppletQr",
                {
                    type: "RICH_TEXT_PREVIEW_PAGE",
                    value: JSON.stringify({
                        path: "pages/welcome/welcome",
                        redirectPath: "/indexPages/previewRichText/index",
                        params: {
                            ...params,
                        },
                    }),
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200 && res.data) {
                    this.textObj.signInUrl = res.data.codeUrl
                    this.toggle1 = true
                    this.$Message.destroy()
                } else {
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: res.desc,
                    })
                }
            })
        },
        // tab切换
        toggleTab(number) {
            this.tabName = number
            this.handleTableData({
                page: this.page,
                pageSize: this.pageSize,
            })
        },

        // 切换菜单
        selectMenu(name) {
            this.showMenu = name
        },
        changeProv(code) {
            if (code.length >= 2) {
                this.cityList = []
                this.regionList = []
                this.streeList = []
                this.projectList = []
                this.city = []
                this.region = []
                this.stree = []
                this.project = []
                this.cityDisabled = true
                this.regionDisabled = true
                this.streeDisabled = true
                this.projectDisabled = true
            } else if (code.length == 1) {
                this.cityDisabled = false
                let arrCode = code[code.length - 1].split("-")
                this.getPulishData(arrCode[arrCode.length - 1], "cityList", "city")
            } else {
                this.regionList = []
                this.streeList = []
                this.projectList = []
                this.city = []
                this.region = []
                this.stree = []
                this.project = []
            }
        },
        // 城市选择
        changeCity(code) {
            if (code.length >= 2) {
                this.regionList = []
                this.streeList = []
                this.projectList = []
                this.region = []
                this.stree = []
                this.project = []
                this.regionDisabled = true
                this.streeDisabled = true
                this.projectDisabled = true
            } else if (code.length == 1) {
                this.regionDisabled = false
                let arrCode = code[code.length - 1].split("-")
                this.getPulishData(arrCode[arrCode.length - 1], "regionList", "region")
            }
        },
        // 区选择
        changeRegion(code) {
            if (code.length >= 2) {
                this.streeList = []
                this.projectList = []
                this.stree = []
                this.project = []
                this.streeDisabled = true
                this.projectDisabled = true
            } else if (code.length == 1) {
                this.streeDisabled = false
                let arrCode = code[code.length - 1].split("-")
                this.getPulishData(arrCode[arrCode.length - 1], "streeList", "stree")
            }
        },
        // 街道选择
        changeStree(code) {
            if (code.length >= 2) {
                this.projectList = []
                this.project = []
                this.projectDisabled = true
            } else if (code.length == 1) {
                this.projectDisabled = false
                let arrCode = code[code.length - 1].split("-")
                this.getPulishData(arrCode[arrCode.length - 1], "projectList", "project")
            }
        },
        // 获取发布范围列表
        getPulishData(code, list, model) {
            this.$get("/datamsg/api/pc/staff/selectCascadeDataScope", {
                orgCode: code,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    this[list] = res.dataList.map(item => {
                        return {
                            value: item.orgPath,
                            label: item.orgName,
                            orgLevel: item.orgLevel,
                        }
                    })
                    if (res.dataList.length == "1") {
                        this[model] = [res.dataList[0].orgPath]
                    }
                }
            })
        },
    },
    watch: {
        pushType: {
            handler(val) {
                this.formData.pushType = val
                console.log("watchType", this.formData.pushType)
            },
            immediate: true,
        },
        buttonRoot:{
            handler(val){
                if(val) this.pushlishRoot = val
            }
        }
    },
    async created() {
        this.dimensionId = window.sessionStorage.getItem("dimensionId")
        sessionStorage.setItem("NODEENV", process.env.NODE_ENV)

        await this.getPulishData("CN", "proList", "prov")
        if (this.activeId) {
            await this.$get("/info/api/pc/information/v2/queryById", {
                infoId: this.activeId,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                terminal: "2",
            }).then(res => {
                if (res.code == 200 && res.data) {
                    if (!this.publicRecommend || this.publicRecommend == "") this.publicRecommend = res.data.recommend
                    this.$Message.destroy()
                     if (res.data.dataScopeList.length == 1) {
                                //回显发布范围
                                res.data.dataScopeList.map(item => {
                                    let arr = item.split("-")
                                    switch (arr.length) {
                                        case 1: 
                                            this.prov = item
                                            break
                                        case 2:
                                            this.prov = arr[0]
                                            this.city = item
                                            break
                                        case 3:
                                            this.prov = arr[0]
                                            this.city = arr[0] + "-" + arr[1]
                                            this.region = item
                                            break
                                        case 4:
                                            this.prov = arr[0]
                                            this.city = arr[0] + "-" + arr[1]
                                            this.region = arr[0] + "-" + arr[1] + "-" + arr[2]
                                            this.stree = item
                                            break
                                        case 5:
                                            this.prov = arr[0]
                                            this.city = arr[0] + "-" + arr[1]
                                            this.region = arr[0] + "-" + arr[1] + "-" + arr[2]
                                            this.stree = arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3]
                                            this.project = item
                                            break
                                    }
                                })
                            } else if (res.data.dataScopeList.length > 1) {
                                res.data.dataScopeList.map(item => {
                                    let arr = item.split("-")
                                    switch (arr.length) {
                                        case 1:
                                            this.prov.push(item)
                                            break
                                        case 2:
                                            this.prov.push(arr[0])
                                            this.city.push(item)
                                            break
                                        case 3:
                                            this.prov.push(arr[0])
                                            this.city.push(arr[0] + "-" + arr[1])
                                            this.region.push(item)
                                            break
                                        case 4:
                                            this.prov.push(arr[0])
                                            this.city.push(arr[0] + "-" + arr[1])
                                            this.region.push(arr[0] + "-" + arr[1] + "-" + arr[2])
                                            this.stree.push(item)
                                            break
                                        case 5:
                                            this.prov.push(arr[0])
                                            this.city.push(arr[0] + "-" + arr[1])
                                            this.region.push(arr[0] + "-" + arr[1] + "-" + arr[2])
                                            this.stree.push(arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3])
                                            this.project.push(item)
                                            break
                                    }
                                })
                                this.prov = Array.from(new Set(this.prov))
                                this.city = Array.from(new Set(this.city))
                                this.region = Array.from(new Set(this.region))
                                this.stree = Array.from(new Set(this.stree))
                                this.project = Array.from(new Set(this.project))
                            }
                    this.formData = {
                        operationVo: res.data.operationVo,
                        dismissList: res.data.dismissList ? res.data.dismissList : [],
                        title: res.data.title,
                        recommend: res.data.recommend && res.data.recommend ? res.data.recommend : "0",
                        pushChannel: res.data.pushChannel === "4" ? true : false, //小程序订阅通知
                        time: [new Date(res.data.startTime), new Date(res.data.endTime)],
                        contactMobile: res.data.contactMobile,
                        terminal: res.data.terminal.split(",").indexOf("0") != -1 ? ["0", "1", "2", "3", "4", "5"] : res.data.terminal.split(","),
                        photo: res.data.photo,
                        content: res.data.content,
                        source: res.data.source,
                        status: res.data.status,
                    }
                    if (res.data.formDetailVo) {
                        this.formDetailVo = res.data.formDetailVo
                        this.changeFormShow = true // 切换为表单修改组件
                    }
                    if (res.data.pushChannelVo && res.data.pushChannelVo.pushType) {
                        this.formData.pushChannelVo = res.data.pushChannelVo
                        this.pushType = res.data.pushChannelVo.pushType && res.data.pushChannelVo.pushType != "" ? res.data.pushChannelVo.pushType.split(",") : []
                    }
                    if (res.data.groupList && res.data.groupList.length != 0) {
                        let arr = []
                        res.data.groupList.map(item => {
                            arr.push(item.groupName)
                        })
                        this.formData.groupNames = arr.join(",")
                        this.formData.channels = res.data.pushChannelVo.channels
                        this.$refs.checkGroup.rightList = res.data.groupList
                    }
                    if (res.data.ruleVos) {
                        res.data.ruleVos.map(item => {
                            if (item.ruleCode == "authentication") {
                                this.ruleVos[0] = {
                                    ruleCode: item.ruleCode,
                                    ruleValue: item.ruleValue == "true" ? true : "",
                                    // ruleValue: item.ruleValue && item.ruleValue != '' ? (item.ruleValue == 'true' ? '1' : item.ruleValue == 'false' ? '2' : '') : '',
                                }
                            } else if (item.ruleCode == "participate_integral") {
                                this.ruleVos[1] = {
                                    ruleCode: item.ruleCode,
                                    ruleValue: item.ruleValue ? Number(item.ruleValue) : 0,
                                }
                            } else if (item.ruleCode == "button_name") {
                                if (item.ruleValue == "立即报名" || item.ruleValue == "立即填报" || item.ruleValue == "立即参与" || item.ruleValue == "立即预约") {
                                    this.ruleVos[2] = {
                                        ruleCode: item.ruleCode,
                                        ruleValue: item.ruleValue,
                                    }
                                } else {
                                    this.ruleVos[2] = {
                                        ruleCode: item.ruleCode,
                                        ruleValue: "自定义",
                                    }
                                    this.formData.buttonName = item.ruleValue
                                }
                            } else if (item.ruleCode == "whether_registration") {
                                this.ruleVos[3] = {
                                    ruleCode: item.ruleCode,
                                    ruleValue: item.ruleValue,
                                }
                            } else if (item.ruleCode == "registration_type") {
                                this.ruleVos[4] = {
                                    ruleCode: item.ruleCode,
                                    ruleValue: item.ruleValue,
                                }
                            } else if (item.ruleCode == "signature") {
                                this.ruleVos[5] = {
                                    ruleCode: item.ruleCode,
                                    ruleValue: item.ruleValue,
                                }
                                if (item.ruleValue == "1" && this.formDetailVo.interactionFormVos) {
                                    //需要签名
                                    let index = this.formDetailVo.interactionFormVos.findIndex(res => res.content == "signature")
                                    this.formDetailVo.interactionFormVos.splice(index, 1)
                                    console.log(this.formDetailVo)
                                }
                            } else if (item.ruleCode == "submit_only") {
                                this.ruleVos[6] = {
                                    ruleCode: item.ruleCode,
                                    ruleValue: item.ruleValue,
                                }
                            } else if (item.ruleCode == "modify_results") {
                                this.ruleVos[7] = {
                                    ruleCode: item.ruleCode,
                                    ruleValue: item.ruleValue,
                                }
                            } else if (item.ruleCode == "join_number_limit") {
                                this.ruleVos[8] = {
                                    ruleCode: item.ruleCode,
                                    ruleValue: item.ruleValue,
                                }
                            }
                        })
                    }
                }
            })
        }

        if(!this.pushlishRoot || this.pushlishRoot == ''){
            this.pushlishRoot = parent.vue.userButtonRoot.includes("1001") ? "1001" : parent.vue.userButtonRoot.includes("1002") ? "1002" : parent.vue.userButtonRoot.includes("1003") ? "1003" : ""
        }
    },
    components: {
        LiefengEditor,
        LiefengModal,
        PreviewForm,
        Mymodel,
        CheckGroup,
    },
    filters: {
        toggleTime(val, that) {
            return that.$core.formatDate(new Date(val), "yyyy-MM-dd hh:mm:ss")
        },
    },
}
</script>

<style scoped lang='less'>
.container {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 123px);
    /deep/.menu {
        height: 100%;
        .ivu-menu {
            height: 100%;
            width: 220px !important;
        }
    }
    .content {
        overflow: auto;
        padding-top: 20px;
        flex: 1;
        height: 100%;
    }
}
/deep/.ivu-tooltip-inner {
    max-width: 100%;
}
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
.dateTime {
    width: 372px;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .ivu-checkbox-wrapper {
        width: 50px;
        height: 40px;
        line-height: 40px;
        margin: 6px;
        text-align: center;
        &.ivu-checkbox-wrapper-checked.ivu-checkbox-border {
            background: #72a2d4;
            color: #fff;
        }
        /deep/.ivu-checkbox {
            display: none;
        }
    }
}
.dateWeek {
    width: 550px;
    display: inline-flex;
    flex-wrap: nowrap;
    text-align: center;
    justify-content: flex-start;
    .ivu-checkbox-wrapper {
        flex: 1;
        &.ivu-checkbox-wrapper-checked.ivu-checkbox-border {
            background: #72a2d4;
            color: #fff;
        }
        /deep/.ivu-checkbox {
            display: none;
        }
    }
}
.tempContainer {
    /deep/.ivu-tabs-content {
        height: calc(100vh - 122px);
        .tempBox {
            height: calc(100% - 35px);
            overflow: auto;
            min-height: 200px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            & > div {
                width: 32%;
                margin: 0.5%;
                .ivu-card-head {
                    padding: 10px;
                    text-align: center;
                }
                .ivu-card-body {
                    padding: 10px;
                    line-height: 30px;
                    min-height: 142px;
                    height: 100%;
                    overflow: auto;
                }
            }
            .noList {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
/deep/.ivu-input[disabled],
/deep/.ivu-input-number-disabled,
/deep/.ivu-input-number-input[disabled],
/deep/fieldset[disabled] .ivu-input,
/deep/.ivu-checkbox-disabled .ivu-checkbox-inner,
/deep/.ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner,
/deep/.ivu-select-disabled .ivu-select-selection,
/deep/.ivu-radio-disabled .ivu-radio-inner {
    background-color: #fff;
    color: #333;
}
</style>
